// Libs.
import React, {useEffect, useState} from 'react';
// Deps.
import SEO from '../../../components/layout/seo';
import CheckoutComponent from '../../../components/checkout/checkout-component';
// Hooks.
import AppLayout from "../../../components/layout/AppLayout";
import Cart from "../../../components/cart/cart";
import NavigationDrawer from "../../../hooks/useNavigationDrawer";
import {get} from "lodash";
import {useStateValue} from "../../../store/state";
import UserLoginMobile from "../../../components/user/user-login-mobile";
import UserRegister from "../../../components/user/user-register";
import appAliases from "../../../data/appAliases";
import navigate from '../../../libs/navigate';
import locale from '../../../locale/locale.json';
import PagePreloader from '../../../components/common/loaders/page-preloader';

const ClassesCheckoutPage = () => {
  const [state] = useStateValue();
  const [userLoggedIn, setUserLoggedIn] = useState(!get(state, 'user.access_token', false));
  const [isRegisterDrawer, setRegisterDrawer] = useState(false);
  const [open, setOpen] = useState(false);
  const drawer = {
    setRegisterDrawer,
  };

  useEffect(() => {
    // just for animation.
    setOpen(true);
  }, []);
  return (
    <AppLayout menu={false}>
      <SEO title="Checkout" />
      <PagePreloader/>
      {!userLoggedIn && (
        <NavigationDrawer menuHide={true} drawerStatus={open} header={locale.checkoutPage.titles.mycart} onClose={() => navigate(appAliases.appCart)}>
          <CheckoutComponent cartType="loft" />
        </NavigationDrawer>
      )}
      <NavigationDrawer header={locale.checkoutPage.titles.mycart} drawerStatus={userLoggedIn} onClose={() => navigate(appAliases.appCart)}>
        <UserLoginMobile drawer={{doLogin:setUserLoggedIn, doRegister:setRegisterDrawer}}/>
      </NavigationDrawer>
      <NavigationDrawer header={locale.checkoutPage.titles.mycart} drawerStatus={isRegisterDrawer} onClose={() => navigate(appAliases.appCart)}>
        <UserRegister drawer={drawer} />
      </NavigationDrawer>
    </AppLayout>
  );
};

export default ClassesCheckoutPage;
